import React, { useState, useEffect } from 'react';
import {Page, Text, View, Document, StyleSheet, Image, Link} from '@react-pdf/renderer';
import SecurepolLogo from './Images/SecurepolLogo';
import WaterMark from './Images/watermark'
import PDFbackgroundline from './Images/PDFbackgroundline'


const PDFDocument = ({ basketID, formData, basket, project, upfront, monthly,tax, contractDuration,pdfID}) => {
  
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  function generateRandomFiveDigit() {
    return Math.floor(10000 + Math.random() * 90000);
  }

  function displayFormattedDate() {
    // Get the current date
    const now = new Date();
  
    // Extract components of the date
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const year = now.getFullYear();
  
    // Format the date
    const formattedDate = `Date: ${month}/${day}/${year}`;
  
    return formattedDate;
  }
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []); // Empty dependency array to run the effect only once

  const styles = StyleSheet.create({
    page:{
      paddingBottom: 10,
    },
    contentWrapper:{
      width:'100%',
      display: 'flex',
      flexDirection: 'column',
      
    },
    headerWrapper:{
      margin:10,
      padding:10 ,
      fontSize:24,
      display: 'flex',
      flexDirection: 'row',
    },
    companyAddressBox:{
      flex:1,
      fontSize:10,
      color: '#274E65',
    },
    image: {
      width:180,
      marginLeft:-6,
      marginBottom:1,
    },
    imageb: {
      position:'absolute',
    },
    addressHeader:{
      fontSize:14,
    },
    addressBody:{
      fontSize:8,
    },
    pdfTitleBox:{
      fontSize:32,
      margin:10,
      color: '#274E65',
      textAlign:'center',
      display: 'flex',
      flexDirection: 'column',
    },
    pdfNumber:{
      fontSize:10,
      textAlign:'right',
    },
    pdfDate:{
      fontSize:10,
      textAlign:'right',
    },
    pdfloc:{
      fontSize:5
    },
    addressWrapper:{
      display: 'flex',
      flexDirection: 'row',
    },
    link: {
      color: '#274E65',
      textDecoration: 'none',
    },
    customerAddressWrapper:{
      padding:20,
      flex:1,
      display: 'flex',
      flexDirection: 'column',
      fontSize:12,
    },
    companyAddressWrapper:{
      padding:20,
      flex:1,
      display: 'flex',
      flexDirection: 'column',
      fontSize:12,
    },
    headerlabel:{
      fontSize:16,
      color:'#274E65',
      fontWeight: 'bold',
      marginBottom: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#333',
    },
    addressBox:{
      display: 'flex',
      flexDirection: 'row',
      fontSize:10,
      color:'#333',
      marginLeft:5,
    },
    address:{
      flexShrink:1,
    },


    itemsListWrapper:{
      padding:10,
      margin:10,
      
    },
    itemsListBody:{
    },
    itemDetails:{
        fontSize:8,
        marginLeft:40,
        color:'#333'
    },
   





    pdfTitle:{
      
      borderColor: '#000',
    },
    
    section: {
      width:'100%',
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft:26,
      marginRight:0,
      marginTop: 10,
      marginBottom: 0,
    },
    tableRow: {
      color:'#274E65',
      marginTop:1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    tableHeader:{
      fontSize:8,
      margin: 4,
    },
    tableCell: {
      margin: 4,
      fontSize: 12,
      flexGrow: 0,
      color:'#333'
    },
    servicesBox:{
      fontSize: 10,
      margin: 5,
    },



    itemDetailsBox:{
      display:'flex',
      flexDirection:'column',
      fontSize: 9,
      marginLeft:40,
      marginRight:40,
      
      color:'#333'
    },
    itemDetailsHeaderBox:{
      display:'flex',
      flexDirection:'row',
      backgroundColor:'#f7f8f9',
    },  
      itemDetailsHeaderLocatoin:{
        flex:1,
      },
      itemDetailsHeaderModel:{
        flex:1,
      },


      textMarg:{
        margin:0.5,
      },
    itemDetailsWrapper:{
    },
    itemDetailsRow:{
      display:'flex',
      flexDirection:'row',
    },
    itemDetailsOrderLabel:{
      flex:1
    },
    itemDetailsModelLabel:{
      flex:1,
    },

    paymentPlanBox:{
      marginTop:40,
    },
    paymentPlanLabel:{
      fontSize:16,
      color: '#274E65',
    },

    paymentDetailBox:{
      
      color:'#000',
      marginLeft:0,
      fontSize:10,
    },


    paymentBoxWrapper:{
      display:'flex',
      flexDirection:'row',
    },
    paymentTermsBox:{
      margin:16,
      flex:1,
    },
    termsBox:{
      margin:4,
      display:'flex',
      flexDirection:'column'
    },
    termRow:{
      margin:1,
      display:'flex',
      flexDirection:'row',
    },
    termLabel:{
      fontSize:8,
      color:'#000',
    },
    termDescriptionFixed:{
      fontSize:8,
      color:'#777',
    },
    termDescription:{
      fontSize:8,
      color:'#777',
    },
    paymentPriceBox:{
    flex:1,
    },





    
      priceWrapper:{
        margin:16,
      },

      
        priceLabel:{
          color: '#274E65',
          borderBottomWidth: 1,
          borderBottomColor: '#eee',
          fontSize:10,
        },
        priceLabelText:{
          padding:2,
          fontSize:14,
        },
        priceBox:{
          padding:2,
          display:'flex',
          flexDirection:'row'
        },
          priceName:{
            flex:1,
            fontSize:10,
            color:'grey',
            textAlign:'right',
          },
          priceAmount:{
            flex:1,
            fontSize:10,
            textAlign:'right',
          },

          footerBox:{
            margin:20,
          },
          footerParagraph:{
            marginTop:20,
            fontSize:10,
          },
          footerParagphBox:{
            marginLeft:20,
            marginRight:20,
          },
          pageNumber: {
            position: 'absolute',
            fontSize: 10,
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
          },
          loc:{
            color:'#eee'
          }
  });
  
  const TableHeader = ({label1, label2}) => (
    <View style={styles.tableRow}>
      <Text style={[styles.tableHeader, { fontWeight: 'bold' }]}>{label1}</Text>
      <Text style={[styles.tableHeader, { fontWeight: 'bold' }]}>{label2}</Text>
    </View>
  );
  
  const TableRow = ({ item, noQty }) => (
    item.count &&(
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{noQty ? "-" : item.count}</Text>
      <Text style={styles.tableCell}>{item.cat}{item.count > 1 ? 's' : ''}</Text> 
    </View>
    )
  );
  

  // Counts items with the same category that do not have the 'monthly' key
function countOccurrences(arr) {
  return arr.reduce((acc, val) => {
    // Check if the 'monthly' key does not exist in the object
    if (!('isService' in val)) {
      // Increment or initialize the count for this category
      acc[val.cat] = (acc[val.cat] || 0) + 1;
    }
    return acc;
  }, {});
}
  const occurrences = countOccurrences(basket);

  const sortedData = project.displayOrder.map(item => ({
      cat: item,
      count: occurrences[item] || 0,
  }));

  // Sorting the sortedData array based on the displayOrder
  sortedData.sort((a, b) => project.displayOrder.indexOf(a.cat) - project.displayOrder.indexOf(b.cat));



  const DeviceListTable = ({ sortedData, basket }) => (
    <View style={styles.table}>
      <TableHeader label1={'Qty'} label2={'Description'}/>
      {sortedData.map((item, index) => {
        const obj = basket.find(obj => (obj.cat === item.cat)&& (!obj.isService));
        const details = basket.filter((obj)=>(obj.cat === item.cat)&& (!obj.isService))
        const noQty = obj && obj.displayNoQty !== undefined && obj.displayNoQty;
        return( 
          <View wrap={false}>
            <TableRow key={index} item={item} noQty={noQty} />
            <View style={styles.itemDetailsBox}>
              {obj &&(
                <View style={styles.itemDetailsHeaderBox}>
                  <View style={styles.itemDetailsHeaderLocatoin}>
                    <Text style={styles.textMarg}>Location</Text>
                  </View>
                  <View style={styles.itemDetailsHeaderModel}>
                    <Text style={styles.textMarg}>Model</Text>
                  </View>
                </View>
              )}
              <View style={styles.itemDetailsWrapper}>
                {details.map((obj,i)=>{
                  return(
                    <View style={styles.itemDetailsRow}>
                      <View style={styles.itemDetailsOrderLabel}>
                        <Text style={styles.textMarg}>{i+1+'. '+obj.loc}</Text>
                      </View>
                      <View style={styles.itemDetailsModelLabel}>
                        <Text style={styles.textMarg}>{obj.model}</Text>
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );





  // Counts items with the same category that do not have the 'monthly' key
function countServicesOccurrences(arr) {
  return arr.reduce((acc, val) => {
    // Check if the 'monthly' key does not exist in the object
    if (('isService' in val)) {
      // Increment or initialize the count for this category
      acc[val.cat] = (acc[val.cat] || 0) + 1;
    }
    return acc;
  }, {});
}
  const servicesOccurrences = countServicesOccurrences(basket);

  const sortedServices = project.displayOrder.map(item => ({
      cat: item,
      count: servicesOccurrences[item] || 0,
  }));

  // Sorting the sortedData array based on the displayOrder
  sortedServices.sort((a, b) => project.displayOrder.indexOf(a.cat) - project.displayOrder.indexOf(b.cat));


  const ServicesTable = ({ sortedServices, basket }) => (
    <View style={styles.table}>
      <TableHeader label1={'Description'}/>
      {sortedServices.map((item, index) => {
        const obj = basket.find(obj => (obj.cat === item.cat)&& (obj.isService));
        const details = basket.filter((obj)=>(obj.cat === item.cat)&& (obj.isService))
        const noQty = obj && obj.displayNoQty !== undefined && obj.displayNoQty;
        return( 
          <View wrap={false}>
            <TableRow key={index} item={item} noQty={noQty} />
            <View style={styles.itemDetailsBox}>
              {obj &&(
                <View style={styles.itemDetailsHeaderBox}>
                  <View style={styles.itemDetailsHeaderLocatoin}>
                    <Text style={styles.textMarg}>Location</Text>
                  </View>
                  <View style={styles.itemDetailsHeaderModel}>
                    <Text style={styles.textMarg}>Model</Text>
                  </View>
                </View>
              )}
              <View style={styles.itemDetailsWrapper}>
                {details.map((obj,i)=>{
                  return(
                    <View style={styles.itemDetailsRow}>
                      <View style={styles.itemDetailsOrderLabel}>
                        <Text style={styles.textMarg}>{i+1+'. '+obj.loc}</Text>
                      </View>
                      <View style={styles.itemDetailsModelLabel}>
                        <Text style={styles.textMarg}>{obj.model}</Text>
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );

  return(
    <Document>
      <Page size="A4"  style={ styles.page}>
      <PDFbackgroundline/>
        <View style={styles.contentWrapper}>
          <View style={styles.headerWrapper}>
            <View style={styles.companyAddressBox}>
              <View style={styles.address}>
                <View style={styles.addressHeader}>
                <Image
                  style={styles.image}
                  src={SecurepolLogo}
                />
                </View>
                <View style={styles.addressBody}>
                  <Text>300, 400 5 Ave SW</Text>
                  <Text>Calgary, AB T2W3C7</Text>
                  <Text>info@securepol.com</Text>
                  <Text>(403) 703-1273</Text>
                  <Text>Lic#: BL184235</Text>
                  <Text>CPS#: 00427</Text>
                </View>
              </View>
            </View>
            <View style={styles.pdfTitleBox}>
              <View style={styles.pdfDate}>
                <Text>{displayFormattedDate()}</Text>
              </View>
              <View style={styles.pdfNumber}>
                <Text>ID: {pdfID}</Text>
              </View>
              <View style={styles.pdfloc}>
                
              </View>
            </View>
          </View>
          <View style={styles.pdfTitleBox}>
              <Text>Security Service Estimate</Text>
            </View>
          <View style={styles.addressWrapper}>
            <View style={styles.customerAddressWrapper}>
              <View style={styles.headerlabel}>
                <Text><Link
        style={styles.link}
        src={`http://maps.apple.com/?q=${latitude},${longitude}`}>1
      </Link>.Customer Information</Text>
              </View>
              <View style={styles.addressBox}>
                <View style={styles.address}>
                  {formData.name!=='' &&(<Text style={styles.textMarg}>{formData.name}</Text>)}
                  {formData.phone!=='' &&(<Text style={styles.textMarg}>{formData.phone}</Text>)}
                  {formData.email !=='' &&(<Text style={styles.textMarg}>{formData.email}</Text>)}
                  {formData.address!=='' &&(<Text style={styles.textMarg}>{formData.address}</Text>)}
                  {formData.city!=='' &&(<Text style={styles.textMarg}>{formData.city}</Text>)}
                  {formData.province!=='' &&(<Text style={styles.textMarg}>{formData.province}</Text>)}
                  {formData.postal!=='' &&(<Text style={styles.textMarg}>{formData.postal}</Text>)}
                  {formData.country!=='' &&(<Text style={styles.textMarg}>{formData.country}</Text>)}

                  
                </View>
              </View>
            </View>

            <View style={styles.customerAddressWrapper}>
              <View style={styles.headerlabel}>
                <Text>2. Service Location</Text>
              </View>
              <View style={styles.addressBox}>
                <View style={styles.address}>
                  {formData.locName !=='' &&(<Text style={styles.textMarg}>{formData.locName}</Text>)}
                  {(formData.locName ==='' && formData.name!=='') &&(<Text style={styles.textMarg}>{formData.name}</Text>)}

                  {formData.locPhone!=='' &&(<Text style={styles.textMarg}>{formData.locPhone}</Text>)}
                  {(formData.locPhone===''&& formData.phone!=='') &&(<Text style={styles.textMarg}>{formData.phone}</Text>)}

                  {formData.locAddress!=='' &&(<Text style={styles.textMarg}>{formData.locAddress}</Text>)}
                  {(formData.locAddress===''&&formData.address!=='') &&(<Text style={styles.textMarg}>{formData.address}</Text>)}

                  {formData.locCity!=='' &&(<Text style={styles.textMarg}>{formData.locCity}</Text>)}
                  {(formData.locCity===''&&formData.city!=='') &&(<Text style={styles.textMarg}>{formData.city}</Text>)}

                  {formData.locProvince!=='' &&(<Text style={styles.textMarg}>{formData.locProvince}</Text>)}
                  {(formData.locProvince==='' && formData.province!=='') &&(<Text style={styles.textMarg}>{formData.province}</Text>)}

                  {formData.locPostal!=='' &&(<Text style={styles.textMarg}>{formData.locPostal}</Text>)}
                  {(formData.locPostal===''&& formData.postal!=='') &&(<Text style={styles.textMarg}>{formData.postal}</Text>)}


                  
                </View>
              </View>
            </View>
          </View>
          <View style={styles.itemsListWrapper}>
            <View style={styles.headerlabel}>
              <Text>3. Equipment Included in Your Package</Text>
            </View>
            <View style={styles.itemsListBody}>
              <DeviceListTable sortedData={sortedData} basket={basket} />
            </View>



            <View style={styles.paymentPlanBox} wrap={false}>
              <View style={styles.headerlabel}>
                <Text>4. Services Included in Your Package</Text>
              </View>
              <View style={styles.itemsListBody}>
                <ServicesTable sortedServices={sortedServices} basket={basket} />
              </View>
            </View> 

            <View style={styles.paymentPlanBox} wrap={false}>
              <View style={styles.headerlabel}>
                <Text>5. Payment Plan</Text>
              </View>
              
              <View style={styles.paymentDetailBox}>
                <View style={styles.paymentBoxWrapper}>
                  <View style={styles.paymentTermsBox}>
                    <View style={styles.paymentPlanTerm}>
                      <View style={styles.priceLabel}>
                      <Text style={styles.priceLabelText}>Terms</Text>
                      </View>
                      <View style={styles.termsBox}>
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Contract Duration: </Text>
                          </View>
                          <View style={styles.termDescription}>
                              <Text>{contractDuration===1? 'Month-to-Month (No long-term contract)':contractDuration+' Months' }</Text>
                          </View>
                        </View>
                        
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Payment Due Upon Completion of Installation: </Text>
                          </View>
                          <View style={styles.termDescription}>
                              <Text>${((upfront*tax)+upfront).toFixed(2)}</Text>
                          </View>
                        </View>

                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Monthly Payment: </Text>
                          </View>
                          <View style={styles.termDescription}>
                              <Text>${((monthly*tax)+monthly).toFixed(2)}</Text>
                          </View>
                        </View>
                        {contractDuration != 1 &&(
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Monthly Payment After Contract: <Text style={styles.termDescriptionFixed}>Reduced to include only the monitoring fee.</Text></Text>
                          </View>
                        </View>
                        )}
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Monthly Payment Requirments: <Text style={styles.termDescriptionFixed}>Scheduled, automatic charges to a credit card (Visa, MasterCard, American Express) or Visa Debit card</Text></Text>
                          </View>
                          
                        </View>
                        {contractDuration != 1 &&(
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Early Termination Fee: <Text style={styles.termDescriptionFixed}>Requires payment of 100% of the remaining contract balance.</Text></Text>
                          </View>
                          <View style={styles.termDescription}>
                              
                          </View>
                        </View>
                        )}
                        {contractDuration === 1 &&(
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Termination Fee: <Text style={styles.termDescriptionFixed}>$0.00</Text></Text>
                          </View>
                          <View style={styles.termDescription}>
                              
                          </View>
                        </View>
                        )}
                        {contractDuration === 1 &&(
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Termination Policy: <Text style={styles.termDescriptionFixed}>30 days notice, no paritial refund.</Text></Text>
                          </View>
                          <View style={styles.termDescription}>
                              
                          </View>
                        </View>
                        )}  
                        {contractDuration === 1 &&(
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Equipment Ownership: </Text>
                          </View>
                          <View style={styles.termDescription}>
                              <Text>Customer-owned</Text>
                          </View>
                        </View>
                        )}
                        {contractDuration != 1 &&(
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Equipment Ownership: <Text style={styles.termDescriptionFixed}>Ownership of the product transfers to the customer once the contract has been fully fulfilled.</Text></Text>
                          </View>
                        </View>
                        )}
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Warranty Duration: </Text>
                          </View>
                          <View style={styles.termDescription}>
                              <Text>5 years (limited).</Text>
                          </View>
                        </View>
                        
                        <View style={styles.termRow}>
                          <View style={styles.termLabel}>
                              <Text>Additional terms and conditions apply. </Text>
                          </View>
                          <View style={styles.termDescription}>
                              <Text></Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.paymentPriceBox}>
                    <View style={styles.priceWrapper}>
                      <View style={styles.priceLabel}>
                          <Text style={styles.priceLabelText}>Upfront Payment</Text>
                        </View>

                        <View style={styles.priceBox}>
                          <View style={styles.priceName}>
                            <Text style={styles.textMarg}>Sub Total:</Text>
                          </View>
                          <View style={styles.priceAmount}>
                            <Text style={styles.textMarg}>${upfront.toFixed(2)}</Text>
                          </View>
                        </View>

                        <View style={styles.priceBox}>
                          <View style={styles.priceName}>
                            <Text style={styles.textMarg}>GST 5%:</Text>
                          </View>
                          <View style={styles.priceAmount}>
                            <Text style={styles.textMarg}>${(upfront*tax).toFixed(2)}</Text>
                          </View>
                        </View>

                        <View style={styles.priceBox}>
                          <View style={styles.priceName}>
                            <Text style={styles.textMarg}>Total:</Text>
                          </View>
                          <View style={styles.priceAmount}>
                            <Text style={styles.textMarg}>${((upfront*tax)+upfront).toFixed(2)}</Text>
                          </View>
                        </View>
                      </View>
                    <View style={styles.priceWrapper}>
                      <View style={styles.priceLabel}>
                        <Text style={styles.priceLabelText}>Monthly Monitoring Fee</Text>
                      </View>

                      <View style={styles.priceBox}>
                        <View style={styles.priceName}>
                          <Text style={styles.textMarg}>Sub Total:</Text>
                        </View>
                        <View style={styles.priceAmount}>
                          <Text style={styles.textMarg}>${monthly.toFixed(2)}</Text>
                        </View>
                      </View>

                      <View style={styles.priceBox}>
                        <View style={styles.priceName}>
                          <Text style={styles.textMarg}>GST 5%:</Text>
                        </View>
                        <View style={styles.priceAmount}>
                          <Text style={styles.textMarg}>${(monthly*tax).toFixed(2)}</Text>
                        </View>
                      </View>

                      <View style={styles.priceBox}>
                        <View style={styles.priceName}>
                          <Text style={styles.textMarg}>Total:</Text>
                        </View>
                        <View style={styles.priceAmount}>
                          <Text style={styles.textMarg}>${((monthly*tax)+monthly).toFixed(2)}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.footerBox}>
            <View style={styles.headerlabel}>
              <Text>Thank You for Your Trust in Securepol Inc.</Text>
            </View>
              <View style={styles.footerParagphBox}>
                <Text style={styles.footerParagraph}>We sincerely appreciate the opportunity to provide you with a security solution tailored to your needs. Our commitment is to deliver excellence and peace of mind through our comprehensive security services.</Text>

                <Text style={styles.footerParagraph}>This estimate has been generated using our innovative online application, which empowers both our team and our valued customers like you. You have the flexibility to customize and modify this estimate directly online, ensuring that the proposed solutions perfectly align with your specific requirements.</Text>

                <Text style={styles.footerParagraph}>For any adjustments or further customization, please feel free to access your estimate anytime online by clicking the link bellow:</Text>
                <Link style={styles.footerParagraph} src={`https://securepol.com/packages?id=${basketID}`}>Click here to edit this estimate.</Link>
                <Text style={styles.footerParagraph}> Our intuitive platform allows you to adjust services, change payment plans, and update equipment options autonomously and conveniently.</Text>

                <Text style={styles.footerParagraph}>Thank you once again for choosing Securepol Inc. We look forward to securing your premises with our reliable and advanced solutions.</Text>
              </View>
            </View>
          </View> 
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
      
      </Page>
    </Document>
  )};

export default PDFDocument;
